// react-leaflet-cluster
@import '../common/react-leaflet-cluster/assets/MarkerCluster.scss';
@import '../common/react-leaflet-cluster/assets/MarkerClusterDefault.scss';

.wrapper {
  @apply w-full max-w-[1230px];
}

.desctop_only,
.desktop_only {
  @apply block;

  @media only screen and (max-width: 767px) {
    @apply hidden #{!important};
  }
}

.desctop_hot_offers,
.desktop_hot_offers {
  @apply flex;

  @media only screen and (max-width: 1880px) {
    @apply hidden;
  }
}

.mobile_only {
  @apply hidden;

  @media only screen and (max-width: 767px) {
    @apply block;
  }
}

.mobile_hot_offers {
  @apply hidden;

  @media only screen and (max-width: 1600px) {
    @apply block;
  }
}

.custom_styles_modal {
  .ant-modal-content {
    box-shadow: 0 0.75rem 3.75rem rgba(24, 24, 24, 0.12);
    @apply rounded-xl;

    .ant-modal-body {
      @apply p-6;
    }
  }

  &_header {
    @apply mb-6 flex items-center justify-between;

    h1 {
      @apply m-0 text-base font-semibold text-[#2c2c2c];
    }

    svg {
      @apply cursor-pointer;
    }
  }
}

#nprogress {
  .bar {
    @apply bg-[#00A389] #{!important};
  }

  .peg {
    box-shadow:
      0 0 0.625rem var(--green),
      0 0 0.313rem var(--green);
  }
}

.notfound {
  @apply flex items-center gap-10 px-0 py-[7.5rem];

  .notfoundleft {
    @apply flex flex-col items-center justify-center;

    h1,
    p {
      @apply m-0;
    }

    span {
      @apply text-base font-semibold text-[#00A389];
    }

    h1 {
      @apply text-4xl font-semibold text-[#101828];
    }

    p {
      @apply mb-[1.688rem] text-[1.25rem] font-normal leading-[1.875rem] text-[#475467];
    }

    button {
      box-shadow: 0 0.063rem 0.125rem 0 #1018280d;
      @apply h-auto rounded-xl bg-[#00A389] px-5 py-3 #{!important};

      span {
        @apply text-[#fff] #{!important};
      }
    }
  }
}

details > summary::-webkit-details-marker {
  display: none !important;
}

.highlight {
  position: relative;
  max-width: max-content;
}

.highlight::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  animation: highlightAnimation 0.8s 0.5s ease-in-out forwards;
  background-color: #00003050;
  transform: scale(1.3);
}

@keyframes highlightAnimation {
  0% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.beta {
  background: var(--color-active-green, #00a389) !important;
  color: #ffffff;
  padding: 0 10px;
  border-radius: 16px;
  position: relative;
  font-size: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  // width: 100%;
  height: 51px;
  cursor: pointer !important;

  @media screen and (max-width: 767px) {
    height: 41px;
    border-radius: 10px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -20px;
    width: 40px;
    height: 100%;
    transform: skew(-30deg);
    background: linear-gradient(
      to right,
      #ffffff10,
      #ffffff20,
      #ffffff30,
      #ffffff40,
      #ffffff50,
      #ffffff60,
      #ffffff70,
      #ffffff80,
      #ffffff70,
      #ffffff60,
      #ffffff50,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10
    );
    animation-name: animNew;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-play-state: running;
  }

  @keyframes animNew {
    from {
      left: -200px;
    }

    to {
      left: 150%;
    }
  }
}

.ant-message {
  @apply z-[10000000000000001] #{!important};
}
