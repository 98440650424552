.footer {
  background: #fff;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;

  &_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 16px;
    border-bottom: 1px solid #F2F2F0;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem 1rem 1.5rem;
      row-gap: 24px;
    }

    &_links {
      display: flex;
      column-gap: 48px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        row-gap: 12px;
      }

      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 19.94px;
        color: #8C8C8E;
      }
    }

    &_messenger {
      display: flex;
      column-gap: 12px;

      svg {
        width: 32px;
        height: 32px;

        rect {
          fill: #F2F2F0;
        }

        path {
          fill: #8C8C8E;
        }
      }
    }
  }

  &_bottom {
    display: flex;
    justify-content: space-between;
    // gap: 24px;
    padding: 24px 1rem 2rem;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 24px 1rem;
      row-gap: 24px;

      span {
        color: #101828 !important;
      }
    }

    a,
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 19.94px;
      color: #8C8C8E !important;
    }
  }
}