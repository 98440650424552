.call_back_modal {
  display: flex;
  flex-direction: column;
  width: 407px;
  &_body {
    :global {
      .ant-form-item {
        margin-bottom: 24px;
        &-label {
          padding-bottom: 4px;
          label {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.003em;
            color: var(--text-dark-grey);
            &::before {
              display: none !important;
            }
          }
        }
        &-control {
          input {
            height: 40px;
            border-radius: 8px;
            border-color: var(--border-grey);
            &:hover,
            &:focus {
              border-color: var(--green);
            }
          }
        }
        &-has-error {
          .ant-input {
            border-color: var(--ant-error-color);
            &:hover,
            &:focus {
              box-shadow: 0 0 0 2px var(--ant-error-color-outline);
              border-color: var(--ant-error-color);
            }
          }
        }
      }
    }
    button {
      width: 100%;
      height: 43px;
      border-radius: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--white);
      text-shadow: none;
    }
  }
}

.description{
  margin-top: 12px;
  text-align: center;
  color: #616161;
  font-size: 13px;
  line-height: 15.73px;
  font-weight: 400;
}
