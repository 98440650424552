.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #ffffff;
  padding: 13px 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  &_top {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EAECF0;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;

    span {
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
    }
  }

  &_bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;

    >div {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #616161;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      background-color: #EAECF0;
      padding: 10px 0;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

.dropDownBtn {
  padding: 10px 16px 10px 16px;
  border-radius: 12px;
  border: 1px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  height: 40px;
  user-select: none;
  min-width: 205px;
}

.roomModal {
  :global {
    .ant-modal-content {
      border-radius: 12px;
    }
  }
}