.container{
    @apply w-full;

    .custom_pagination_container {
        @apply flex justify-center items-center h-2  mt-3 mb-5 w-full;
    
        .custom_pagination_pro {
            @apply w-max h-2 flex justify-center gap-2 items-center;
    
            :global {
                .swiper-pagination-bullet {
                    @apply w-2 h-2 rounded-full m-0 bg-gray-500;    
                }
    
                .swiper-pagination-bullet-active {
                    @apply w-2 h-2 rounded-full bg-[var(--highlight-blue)];
                }
            }
        }
    }
}