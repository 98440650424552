$transition: transform 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99),
  opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);

.mobile_menu {
  position: fixed;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 100000;
  overflow: hidden;
  transition: all 0.3s ease-out, background-color 1s ease-out;
  background-color: var(--white);
  transition-delay: 0.25s;

  &.active {
    height: 100%;
    overflow-y: auto;
    transition-delay: 0.25s;
    background-color: var(--body-bg);
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    transition: 0.3s 0.15s ease-in-out;
    padding: 1rem 12px;
    row-gap: 12px;

    &_body {
      display: flex;
      flex-direction: column;

      ul {
        padding: 0;
        list-style: none;
      }

      &.active {
        .title {
          transition-delay: 0.25s;
          transform: scale(1);
          opacity: 1;
        }

        .menu a {
          @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
              transition-delay: #{0.25}s;
            }
          }

          transform: scale(1);
          opacity: 1;
        }
      }

      .menu {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        padding-bottom: 4px;

        li {
          border-bottom: 1px solid #EAECF0 !important;

          &:last-child {
            a {
              border: none !important;
            }
          }

        }

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px;
          padding-bottom: 12px;
          background-color: var(--white);
          transform: scale(0);
          opacity: 0;
          transition: $transition;
          border-bottom: 1px solid #EAECF0;

          @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
              transition-delay: #{0.25 - ($i * 0.05)}s;
            }
          }

          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #344054;
          }

          svg path {
            stroke: var(--text-dark-grey);
          }
        }

      }
    }
  }
}