.regions::-webkit-scrollbar {
  width: 10px !important;
  height: 3.2px !important;
  background: transparent;
  position: absolute;
}

.regions::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
  cursor: pointer;
}