.filterMetro {
  width: 100%;

  :global {
    .ant-select-selector {
      height: 100%;
      border: none !important;

      .ant-select-selection-item {
        height: 32px;
        background-color: #F2F4F7;
        border: none !important;
        border-radius: 8px;

        .ant-select-selection-item-remove {
          display: flex;
          align-items: center;
          justify-content: center;

          .anticon-close {
            width: 16px;
            height: 16px;
            background-color: #D0D5DD;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .ant-select-selection-item-content {
        display: flex;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
        }

      }
    }

    .ant-select-selection-overflow-item {
      height: 100%;
    }

    .ant-select-selection-search {
      width: auto;
      height: 100%;
      padding-bottom: 4px;

      input {
        height: 100%;
      }
    }

    // .ant-select-selection-search-input {
    //   width: max-content !important;
    // }
  }
}