.login_modal {
  width: 407px !important;
  &_body {
    :global {
      .ant-form-item {
        margin-bottom: 24px;
        &-label {
          padding-bottom: 4px;
          label {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.003em;
            color: var(--text-dark-grey);
            &::before {
              display: none !important;
            }
          }
        }
        &-control {
          input {
            height: 40px;
            border-radius: 8px;
            border-color: var(--border-grey);
            &:hover,
            &:focus {
              border-color: var(--green);
            }
          }
        }
      }
    }
    .login_modal_phone {
      display: none;
      &.active {
        display: block;
      }
    }
    .login_modal_confirmation {
      display: none;
      &.active {
        display: block;
      }
      .login_modal_code {
        display: flex;
        width: 100%;
        :global {
          .ant-form-item-control-input-content {
            > div {
              width: 100%;
              justify-content: space-between;
            }
          }
        }
        > div {
          width: 100% !important;
          justify-content: space-between;
          margin-bottom: 24px;
          .otp {
            width: 58px !important;
            input {
              width: 100% !important;
              height: 69px;
              border-radius: 10px;
              border: 1.3px solid rgba(86, 103, 137, 0.26);
              padding: 0 !important;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-weight: 600;
              font-size: 32px;
              line-height: 39px;
              color: var(--green);
              transition: 0.3s ease-in-out;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              &:focus {
                border : {
                  color: var(--green);
                }
              }
              outline: none;
            }
          }
        }
        :global {
          .ant-form-item {
            &:not(:last-child) {
              margin-right: 16px;
            }
            input {
              border-radius: 10px;
              border: 1.3px solid rgba(86, 103, 137, 0.26);
              padding: 0;
              width: 58px;
              height: 69px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              font-weight: 600;
              font-size: 32px;
              line-height: 39px;
              color: var(--green);
              transition: 0.3s ease-in-out;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              &:focus {
                border : {
                  color: var(--green);
                }
              }
              outline: none;
            }
          }
        }
      }
    }
    .code_sended_phone {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.003em;
      color: var(--text-black);
      margin-bottom: 8px;
    }
    .change_phone {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      width: max-content;
      svg {
        transform: rotate(180deg);
        margin-right: 4px;
        path {
          stroke: var(--blue);
        }
      }
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.003em;
        color: var(--blue);
        cursor: pointer;
      }
    }
    .resend_code {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.003em;
      color: var(--blue);
      margin-bottom: 24px;
      cursor: pointer;
      width: max-content;
      &_countdown {
        color: var(--text-black);
        width: max-content;
        .time {
          color: var(--blue);
        }
      }
    }
    .next_btn,
    .submit_btn {
      width: 100%;
      height: 43px;
      border-radius: 6px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--white);
      text-shadow: none;
      &[disabled] {
        background-color: var(--border-grey);
        border-color: var(--border-grey);
        &:hover {
          color: var(--white);
        }
      }
    }
  }
}
