.container {
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1111;
  align-self: center;
  background: #00A389;
  height: 46px;
}

.canceled {
  display: none;
}

.logoAndInfoContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
  flex: 1;
}

.iconImage {
  width: 41px;
  height: 41px;
  border-radius: 8px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;

  >p {
    font-size: 13px;
    font-weight: 700;
    color: white;
    line-height: 15.75px;
  }

  >span {
    color: white;
    font-size: 11px;
    line-height: 16.41px;
  }
}

.installAppBtn {
  border-radius: 10px !important;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600 !important;
  background-color: white !important;
  color: #00A389 !important;
  width: max-content !important;
  height: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 5.5px 12px !important;
  box-shadow: none !important;
  text-shadow: none !important;
  cursor: pointer;
  border: 0 !important;
}

.cancelBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 32px;
  height: 30px;
  padding: 6px;
  background-color: white;
}