.container {
  font-family: "Oswald", sans-serif;

  :global {
    .ant-btn.ant-btn-primary {
      background: #1b815f;
      border: none;

      &:focus,
      &:hover {
        background: #1b815f;
        border: none;
      }
    }
  }
}