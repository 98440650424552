.dropDown {
  background-color: #fff;
  padding: 24px;
  width: 373px;
  box-shadow: 0px -0.09057600051164627px 2.8531439304351807px 0px #B5B5B50F, 0px -0.25043103098869324px 7.888577461242676px 0px #B5B5B516, 0px -0.6029414534568787px 18.992656707763672px 0px #B5B5B51C, 0px -2px 63px 0px #B5B5B52B;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and (max-width: 576px) {
    width: 340px;
  }

  &_top {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #E4E4EA;
  }

  &_item {
    display: flex;
    align-items: center;
    gap: 16px;

    label {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #616161;
      min-width: 58px;
    }

    &_elem {
      display: flex;
      align-items: center;
      gap: 16px;

      a,
      span {
        padding: 4px 16px;
        background-color: #F5F5F5;
        border-radius: 32px;
        font-size: 16px;
        font-weight: 500;
        color: #616161;
        cursor: pointer;
      }
    }
  }

  button {
    height: 43px;
    border-radius: 12px;
  }
}

.dropDownChildren {
  display: flex;
  align-items: center;
  gap: 24px;
  cursor: pointer;

  @media screen and (max-width: 576px) {
    gap: 0;

    span {
      padding: 8px;
    }
  }

  span {
    color: #616161;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
  }

  .line {
    width: 2px;
    height: 22px;
    background-color: #EBEBEB;
  }
}