.header_bottom {

  @media screen and (max-width: 767px) {
    border-bottom: 1px solid var(--border-grey);
  }

  nav {
    height: 100%;
  }

  &_content {
    flex: 1;
    display: flex;
    column-gap: 58px;
    align-items: center;
    height: 70px;

    @media screen and (max-width: 767px) {
      column-gap: 0;
      justify-content: space-between;
      height: 48px;
    }

    @media screen and (max-width: 1280px) {
      padding: 0 12px;
    }

    &_left {
      display: flex;
      align-items: center;

      @media screen and (max-width: 1280px) {
        column-gap: 1rem;
      }

      .hamburger {
        cursor: pointer;
      }

      &_menuIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
      }
    }

    &_center {
      height: 100% !important;
      display: flex;
      gap: 2px;
      padding: 0;
      margin: 0;
      list-style-type: none;
      width: 100%;

      &:first-of-type {
        a {
          padding-left: 0;
        }
      }


      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #475467;
        height: 100%;
        padding: 0 34px;
        transition: 0.3s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        svg {
          path {
            transition: all 0.15s ease-in-out;
            fill: #475467;
          }
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          background-color: transparent;
          transition: all 0.15s ease-in-out;
        }

        &:hover {
          color: var(--green);

          svg {
            path {
              fill: var(--green) !important;
            }
          }
        }

        &.active::after {
          background-color: var(--green);
        }

        &.active {
          svg {
            path {

              fill: var(--green) !important;
            }
          }

          span {
            color: var(--green);
          }
        }
      }

    }

    &_right {
      display: flex;
      height: 100%;

      .item {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 15px;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.root_link {
  svg {
    @media only screen and (max-width: 768px) {
      width: 101px;
      height: 27.81px;
    }
  }
}