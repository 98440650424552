.container {
  background: var(--white);
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding: 32px 0;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 768px) {
    height: 100%;
    padding: 0;
  }

  &_body,
  &_bodyCollapse {
    max-width: 1230px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &_bodyCollapse {
    display: none;

    :global {
      .ant-collapse-item {
        border: none;

        .ant-collapse-header .ant-collapse-arrow {
          top: 20% !important;
        }
      }

      .ant-collapse-borderless {
        background-color: #fff;
      }

      .ant-collapse-header {
        padding: 0 !important;
      }

      .ant-collapse-content-box {
        padding: 0;
        padding-top: 20px !important;
        padding-left: 0 !important;
      }
    }

    .metros_collapse {
      :global {
        .ant-collapse-item {
          border-radius: 8px !important;

          .ant-collapse-header .ant-collapse-arrow {
            top: 20% !important;
          }
        }

        .ant-collapse-borderless>.ant-collapse-item {
          border-bottom: 0 !important;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    &_bodyCollapse {
      display: flex;
    }

    &_body {
      display: none;
    }
  }

  &_elements {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;

    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 500px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media screen and (max-width: 1280px) {
    height: 100%;
    padding: 16px 16px 0px 16px;
  }
}