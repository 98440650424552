.header {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: white;
  top: 0;
  will-change: transform;
  transition: transform 0.15s ease; 
}

.visible {
  transform: translateY(0);
}

.hidden {
  transform: translateY(-100%);
}