.coinModal {
  :global {
    .ant-modal-content {
      border-radius: 12px;
      overflow: hidden;

      .ant-modal-header {
        border-bottom: none;
      }
    }
  }
}

.coin_container{
  background-image: url('../../../../assets/images/uysot-coin.png');
  background-position: center;
  background-size: cover;
}