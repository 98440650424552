.heart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &.checked {
    svg path {
      fill: var(--red);
      stroke: var(--red);
    }

    svg {
      animation: checked 0.3s ease-in-out forwards;
    }

    @keyframes checked {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.5);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  &.unChecked {
    svg {
      animation: unChecked 0.3s ease-in-out forwards;
    }

    @keyframes unChecked {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.5);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  svg {
    cursor: pointer;

    path {
      transition: 0.3s ease-in-out;
    }
  }

  input {
    display: none !important;
  }
}