.messageModal{
  :global{
    .ant-modal-content{
      border-radius: 12px;
      overflow: hidden;
    }
    .ant-modal-body{
      display: none;
    }
  }
}

.messageTitle{
  display: flex;
  gap: 20px;
  svg{
    min-width: 40px;
    min-height: 40px;
  }
  &_right{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 2px;
    h4{
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #101828;
      margin: 0;
    }
    span{
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #475467;
    }
  }
}