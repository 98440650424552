.item {
  z-index: 1001;

  :global {
    .ant-drawer-content {
      border-top-left-radius: 16px !important;
      border-top-right-radius: 16px !important;
    }

    .ant-drawer-header {
      display: none;
    }

    .ant-drawer-body {
      padding: 0;
    }
  }
}

.regionItem {
  z-index: 1001;

  :global {
    .ant-drawer-header {
      display: none;
    }

    .ant-drawer-body {
      padding: 0;
    }
  }
}