.layout {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  min-height: 100vh;

  &_header {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    transition: all linear 0.4s;
  }

  .distance {
    width: 100%;
    height: 0;

    @media screen and (max-width: 767px) {
      padding-bottom: 102px;

      &.has_address {
        padding-bottom: 54px;
      }
    }
  }

  &_children {
    display: flex;
    flex-direction: column;
  }

  &_children_with_app_suggestion {
    margin-top: 60px;
  }
}