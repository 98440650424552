.app_download {
  background: var(--white);
  display: flex;
  justify-content: center;
  height: 236px;
  margin-top: auto;
  box-shadow: -85px -15px 38.4px 0px #00000008 inset;

  @media screen and (max-width: 768px) {
    height: 100%;
    padding: 24px 16px;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: self-start;
    }

    h3 {
      width: 438px;
      font-size: 28px;
      line-height: 33px;
      font-weight: 600;
      margin-bottom: 30px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .app_links {
      a {
        margin-right: 20px;
      }
    }

    .app_phone {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .qr_link {
      p {
        width: 153px;
        font-size: 16px;
        line-height: 19px;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}