.dropDownBtn {
  padding: 10px 16px 10px 16px;
  border-radius: 12px;
  border: 1px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  background: #ffffff;
  height: 40px;
  user-select: none;
}


.dropdownOverlay {
  background-color: #ffffff;
  width: 420px;
  padding: 13px 16px;
  padding-bottom: 0;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  border-radius: 12px;

  :global {

    .ant-input-affix-wrapper {
      border-radius: 4px;
    }
  }
}