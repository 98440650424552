.modal {
  user-select: none;

  :global {

    .ant-modal-body {
      max-height: 80vh;
      overflow: auto;
    }

    .ant-modal-content {
      border-radius: 12px;
    }

    .ant-modal-header {
      border-radius: 12px 12px 0 0;
    }

    .ant-form-item {
      margin: 0;
    }

    .ant-input-affix-wrapper,
    input {
      border-radius: 4px;
    }

    .ant-select-selector {
      border-radius: 4px !important;
    }

  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_clear {
    display: flex !important;
    align-items: center !important;
    gap: 14px !important;
    cursor: pointer;
    border: 0 !important;
    box-shadow: none !important;

    &::after,
    &::before {
      display: none !important;
    }

    span {
      color: #F23535;
      font-size: 14px;
    }
  }
}